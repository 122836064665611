import request from "@/api/request.js";
//  热搜分布
export function topicHostSearch(data){
  return request({
      url:'api/topicnew/send/topicHostSearch'+`?pageNum=${data.pageNum}&pageSize=${data.pageSize}`,
      method:'post',
      data
  })
}
// 话题深度更新后端返回话题数量
export function listUpdate(data) {
  return request({
    url: "api/topic/analysis/listUpdate",
    method: "post",
    data: data,
  });
}
// 这里面存关于话题的请求
export function topicList(data) {
  return request({
    url: "api/topic/analysis/list",
    method: "post",
    data: data,
  });
}
// 话题列表带分页
export function topicLists(data, url) {
  return request({
    url: "api/topic/analysis/list" + url,
    method: "post",
    data: data,
  });
}
// 话题列表不带分页
export function topicListData(data) {
  return request({
    url: "api/topic/analysis/list",
    method: "post",
    data: data,
  });
}
export function getInfoAmountCount(data) {
  return request({
    url: "api/topic/viewPoint/getInfoAmountCount",
    method: "post",
    data: data,
  });
}
export function addPreservation(data) {
  return request({
    url: "api/topic/analysis/add",
    method: "post",
    data: data,
  });
}

// 获取详情
export function getInfo(data) {
  return request({
    url: "api/topic/analysis/getInfo",
    method: "get",
    params: data,
  });
}
// 删除话题
export function deletes(data) {
  return request({
    url: "api/topic/analysis/remove",
    method: "post",
    data: data,
  });
}
// 修改话题
export function editTopic(data) {
  return request({
    url: "api/topic/analysis/edit",
    method: "post",
    data: data,
  });
}
// 首发媒体
export function firstMedia(data) {
  return request({
    url: "api/topic/viewPoint/startMedia",
    method: "get",
    params: data,
  });
}
// 事件进展曲线
export function eventLine(data) {
  return request({
    url: "api/topic/viewPoint/topicProgress",
    method: "get",
    params: data,
  });
}
// 评论曲线
export function commentLine(data) {
  return request({
    url: "api/topic/viewPoint/commentCurve",
    method: "get",
    params: data,
  });
}
// 微博传播
export function microBlog(data) {
  return request({
    url: "api/topic/send/getInfoAmountCount",
    method: "get",
    params: data,
  });
}
// 观点分析
export function viewPoint(data) {
  return request({
    url: "api/topic/send/viewPoint",
    method: "get",
    params: data,
  });
}

// 媒体热力
export function mediaHot(data) {
  return request({
    url: "api/topic/send/mediaHeat",
    method: "post",
    data: data,
  });
}
// 开始抽样
export function topicSample(data) {
  return request({
    url: "api/topic/topicSample/add",
    method: "post",
    data: data,
  });
}
// 抽样列表
export function topicSampleList(data) {
  return request({
    url: "api/topic/topicSample/list",
    method: "post",
    data: data,
  });
}
// 抽样删除
export function deleteSamp(sampleIds) {
  return request({
    url: "api/topic/topicSample/" + sampleIds,
    method: "get",
  });
}

// 增加网民观点
export function addViewpoint(data) {
  return request({
    url: "api/topic/topicViewpoint/add",
    method: "post",
    data: data,
  });
}
// 网民观点列表
export function addViewList(data) {
  return request({
    url: "api/topic/topicViewpoint/list",
    method: "post",
    data: data,
  });
}
//评论情况列表
export function commentList(data, url) {
  return request({
    url: "api/topic/topicComment/list" + url,
    method: "post",
    data: data,
  });
}
//评论情况列表删除
export function deleteCommentList(commentIds) {
  return request({
    url: "api/topic/topicComment/" + commentIds,
    method: "get",
  });
}
//全部信息列表
// export function allMessageList(data, url) {
//   return request({
//     url: "api/topic/topicMsg/list" + url,
//     method: "post",
//     data: data,
//   });
// }
export function allMessageList(data, url) {
  return request({
    url: "api/topicnew/topicMsg/list" + url,
    method: "post",
    data: data,
  });
}
//全部信息列表删除
export function deleteMessageList(data) {
  return request({
    url: "api/topicnew/topicMsg/deleteMsg",
    method: "post",
    data:data
  });
}
//删除分享的机构话题
export function clientDeleteMsg(data) {
  return request({
    url: "api/topic/topicMsg/clientDeleteMsg",
    method: "post",
    data: data
  });
}
//分享话题深度更新
export function share(data) {
  return request({
    url: "api/topic/analysis/share",
    method: "post",
    data: data
  });
}
//网民观点删除
export function deleteViewpoint(viewpointIds) {
  return request({
    url: "api/topic/topicViewpoint/" + viewpointIds,
    method: "get",
  });
}
// //设置突出样本
export function editComment(data) {
  return request({
    url: "api/topic/topicComment/edit",
    method: "post",
    data: data,
  });
}
// //批量设置突出样本
export function editNumComment(data) {
  return request({
    url: "api/topic/topicComment/editYangben",
    method: "post",
    data: data,
  });
}
//对评论设置观点
export function editView(data) {
  return request({
    url: "api/topic/topicComment/updateView",
    method: "post",
    data: data,
  });
}
//媒体观点列表
export function mediaList(data) {
  return request({
    url: "api/topic/viewPoint/list",
    method: "post",
    data: data,
  });
}
//媒体观点列表新增
export function addmediaList(data) {
  return request({
    url: "api/topic/viewPoint/add",
    method: "post",
    data: data,
  });
}
//媒体观点列表合并
export function mergeLook(data) {
  return request({
    url: "api/topic/viewPoint/mergePoint",
    method: "post",
    data: data,
  });
}
//媒体观点列表删除
export function deleteMedia(mediaViewIds) {
  return request({
    url: "api/topic/viewPoint/" + mediaViewIds,
    method: "get",
  });
}
//全部信息修改
export function settingLook(data) {
  return request({
    url: "api/topic/topicMsg/edit",
    method: "post",
    data: data,
  });
}
//编辑媒体观点
export function editMedia(data) {
  return request({
    url: "api/topic/viewPoint/edit",
    method: "post",
    data: data,
  });
}
//编辑网民观点
export function editviewPoint(data) {
  return request({
    url: "api/topic/topicViewpoint/edit",
    method: "post",
    data: data,
  });
}
//导出网民观点
export function exportLook(data) {
  return request({
    url: "api/topic/topicViewpoint/export",
    method: "post",
    data: data,
    responseType: "blob",
  });
}

export function getInfoKeMsg(data) {
  return request({
    url: "api/topic/topicMsg/getInfoKe",
    method: "post",
    data,
  });
}
export function getInfoMsg(data) {
  return request({
    url: "api/topic/topicMsg/getInfo",
    method: "get",
    params: data,
  });
}
// 观点匹配
export function matchData(data) {
  return request({
    url: "api/topic/viewPoint/mete",
    method: "post",
    data: data,
  });
}
// 导出数据
export function exportData(data) {
  return request({
    url: "api/topic/export/data",
    method: "post",
    data: data,
    responseType: "blob"
  });
}
//关键词云
export function frequency(data) {
  return request({
    url: "api/topic/topicMsg/frequency",
    method: "post",
    data: data
  });
}

export function keyWords(data) {
  return request({
    url: "api/topic/analysis/keyWords",
    method: "post",
    data: data
  });
}

//事件概况编辑
export function eventOverview(data) {
  return request({
    url: "api/topic/viewPoint/eventOverview",
    method: "post",
    data: data
  });
}

//境外话题信息数量
export function getOverseasType(data){
  return request({
    url: "api/topic/overseas/getOverseasType",
    method: "get",
    params: data
  });
}

//境外话题媒体分析
export function topicOverFirstType(data){
  return request({
    url: "api/topic/overseas/topicOverFirstType",
    method: "get",
    params: data
  });
}

//境外话题事件进展曲线
export function topicOverProgress(data){
  return request({
    url: "api/topic/overseas/topicOverProgress",
    method: "get",
    params: data
  });
}

//境外话题信息
export function topicOverList(data,url){
  return request({
    url: "api/topic/overseas/topicOverList"+url,
    method: "post",
    data: data
  });
}

//境外话题删除信息
export function deleteId(data){
  return request({
    url: "api/topic/overseas/deleteId",
    method: "get",
    params: data
  });
}

//境外话题信息详情
export function getInfoKe(data){
  return request({
    url: "api/topic/overseas/getInfoKe",
    method: "get",
    params: data
  });
}

//信息地域接口
export function ascription(data){
  return request({
    url: "api/topic/send/ascription",
    method: "get",
    params: data
  });
}

//省市地区获取
export function list(data){
  return request({
    url: "api/system/regional/list",
    method: "post",
    data: data
  });
}

//话题专向报告导出图片接口
export function specialData(data) {
  return request({
      url: 'api/topicnew/word/specialData',
      method: 'get',
      params: data
  })
}

// 单条报告（新模板）
export function topicAIReportNewV4(data) {
  return request({
      url: 'api/topicnew/word/v4/topicAIReport/' + data.id,
      method: 'post',
      responseType: 'blob',
      data: data
  })
}